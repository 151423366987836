<template>
  <div>
    <div class="vx-row ml-1 mt-3">
      <div class="vx-row mb-6" style="width:70%">
        <div class="vx-col sm:w-1/4 w-full flex items-center">
          <span>Warehouse</span>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <Warehouse
            :value="this.selectedWarehouse"
            @selected="(val) => { this.selectedWarehouse = val }"
          ></Warehouse>
        </div>
      </div>
      <div class="vx-row mb-6" style="width:70%">
        <div class="vx-col sm:w-1/4 w-full flex items-center">
          <span>Document Date</span>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <Date
            :default="false"
            @selected="(val) => { this.countingDate = val }"
          ></Date>
        </div>
      </div>
      <div class="vx-row mb-6" style="width:70%">
        <div class="vx-col sm:w-1/4 w-full flex items-center">
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <vs-button color="primary" @click="handleFilter()">Filter</vs-button>
        </div>
      </div>
    </div>

    <div class="vx-row ml-1 mt-3">
      <vs-button color="primary" icon-pack="feather" icon="icon-plus" v-if="hasPermission('create')" @click="handleUpload()">Upload</vs-button>
    </div>

    <div class="vx-row mt-3">
      <div class="vx-col w-full mb-base">
        <Table
          :draw="draw"
          :selectedWarehouse="selectedWarehouse"
          :countingDate="countingDate"
          :status="status"
        ></Table>
      </div>
    </div>
  </div>
</template>

<script>
import Date from "../component/Date.vue";
import Table from "../component/Table.vue";
import Warehouse from "../component/ToWarehouse.vue";

export default {
  components: {
    Table,
    Date,
    Warehouse,
  },
  data() {
    return {
      draw: 0,
      countingDate: null,
      status: 0,
      selectedWarehouse: null,
    };
  },
  methods: {
    handleUpload() {
      this.$router.push({
        name: "saf-create",
        query: {action: "import"},
      });
    },

    handleFilter() {
      this.draw++;
    },

    getPermission() {
      this.$vs.loading();
      this.$http
        .get("/api/account/v1/permission", {
          params: {
            module: "saf",
            source: "WMS"
          }
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code === 200) {
            this.permission = resp.data;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        });
    },
  },
  mounted() {
    this.getPermission();
  },
  computed: {
    hasPermission() {
      return (guardName) => {
        return this.permission.some((perm) => perm.GuardName === guardName);
      };
    },
  },
}
</script>
